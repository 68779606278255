import React from "react"

export default function Section2() {
  return (
    <div className="w-full py-20 bg-accent relative"
    // style={{
    //   backgroundImage:`url(${require("../../../assets/images/img-macbook.png")})`,
    //   backgroundRepeat:'no-repeat',
    //   backgroundSize:'50%',
    //   backgroundPosition:'left',
    // }}
    >
      <div className="lg:w-1/2 md:w-full lg:absolute lg:mt-20">
              <img 
                  className=""
                  src={require("../../../assets/images/img-macbook.png")}
                  alt=""
                  width='100%'
              />
              {/* &nbsp; */}
          </div>
      <div className="container lg mx-auto py-20">
        <div className="lg:flex w-full">
          <div className="lg:w-1/2 md:w-full">
              {/* <img 
                  className=""
                  src={require("../../../assets/images/img-macbook.png")}
                  alt=""
                  width='100%'
              /> */}
              &nbsp;
          </div>
          {/* sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 */}
          <div className="lg:w-1/2 md:w-full p-10 md:p-0">
              <div className="w-full md:py-0 lg:pt-0 xl:pt-0">
                  <p className="text-3xl 2xl:text-4xl text-center text-black text-aileron-bold">
                  Build, Lead, and Serve your community better with digital technology made easy
                  </p>
                  <div className="divider bg-white my-8 mx-auto"></div>
                  <div className="w-full">
                    <p className="text-lg 2xl:text-xl text-center leading-loose text-black pb-10">
                    <strong className="text-black text-aileron-bold text-xl">Why Ciergio?</strong>
                    <br/>
                    Ciergio delivers Superior Communication and Real-Time Coordination which leads to Community Mobilization in an integrated, contact-less and paper-less manner. 
                    </p>
                    <p className="text-lg 2xl:text-xl text-center leading-loose text-black pb-10">
                    <strong className="text-black text-aileron-bold text-xl">“Share-and-Go”</strong>
                    <br/>
                    Quick and easy to implement as no special hardware is needed. Just download the app from Google Play Store and App Store.
                    </p>
                    <p className="text-lg 2xl:text-xl text-center leading-loose text-black pb-10">
                    Ciergio is a secure & comprehensive platform for both private and public communities and organizations.
                    </p>
                    <p className="text-lg 2xl:text-xl text-center leading-loose text-black">
                    Ciergio can seamlessly take your offline community online. Quickly cascade announcements, important events, and other key notifications to your members in real-time with your -- CIERGIO “Share-and-Go” Community Platform!
                    </p>
                  </div>
              </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}